export const SET_DATA = 'SET_DATA';
export const SORT_BY_TOTALDEATHS = 'SORT_BY_TOTALDEATHS';
export const SORT_BY_TOTALDEATHS_FOLLOW = 'SORT_BY_TOTALDEATHS_FOLLOW';
export const SORT_BY_TOTALRECOVEREDS = 'SORT_BY_TOTALRECOVEREDS';
export const SORT_BY_TOTALRECOVEREDS_FOLLOW = 'SORT_BY_TOTALRECOVEREDS_FOLLOW';
export const SORT_BY_TOTALCONFIRMEDS = 'SORT_BY_TOTALCONFIRMEDS';
export const SORT_BY_TOTALCONFIRMEDS_FOLLOW = 'SORT_BY_TOTALCONFIRMEDS_FOLLOW';
export const SORT_BY_NEWCONFIRMEDS = 'SORT_BY_NEWCONFIRMEDS';
export const SORT_BY_NEWCONFIRMEDS_FOLLOW = 'SORT_BY_NEWCONFIRMEDS_FOLLOW';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_COUNTRY = 'SET_COUNTRY';
export const ADD_COUNTRY = 'ADD_COUNTRY';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';